import { AbpHttpInterceptor } from '@abp/abpHttpInterceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import * as ApiServiceProxies from './service-proxies';

@NgModule({
    providers: [
        ApiServiceProxies.OpenAIResponseServiceProxy,
        ApiServiceProxies.AzureAIHttpClientServiceProxy,
        ApiServiceProxies.SipServiceProxy,
        ApiServiceProxies.ResAttendanceAnalyticsServiceProxy,
        ApiServiceProxies.HREmpPerEvalReportsServiceProxy,
        ApiServiceProxies.HREmpPerEvalServiceProxy,
        ApiServiceProxies.CommonMastersServiceProxy,
        ApiServiceProxies.UserAdditionalInfoServiceProxy,
        ApiServiceProxies.ResAttendanceRosterServiceProxy,
        ApiServiceProxies.LtssAuthorisedServicesExcelMetaDataServiceProxy,
        ApiServiceProxies.LTSSUploadHttpClientServiceProxy,
        ApiServiceProxies.LtssUploadLogServiceProxy,
        ApiServiceProxies.LtssAuthorizationServiceProxy,
        ApiServiceProxies.ServiceNotesDashboardServiceProxy,
        ApiServiceProxies.ServiceEnrollmentsServiceProxy,
        ApiServiceProxies.ServicesServiceProxy,
        ApiServiceProxies.ServiceNotesServiceProxy,
        ApiServiceProxies.WeightChartServiceProxy,
        ApiServiceProxies.ConsentsServiceProxy,        
        ApiServiceProxies.SleepChartActivitiesesServiceProxy,
        ApiServiceProxies.SleepChartEntriesesServiceProxy,
        ApiServiceProxies.SleepChartsServiceProxy,
        ApiServiceProxies.AnnouncementsServiceProxy,
        ApiServiceProxies.GraphApiServiceProxy,

        ApiServiceProxies.HRSTDashboardServiceProxy,

        ApiServiceProxies.NutritionAssessmentDashboardServiceProxy,
        ApiServiceProxies.FiredrillDashboardServiceProxy,
        ApiServiceProxies.FacilityManagementDashboardServiceProxy,
        ApiServiceProxies.CRSTDashboardServiceProxy,
        ApiServiceProxies.MedAdminDashboardServiceProxy,
        ApiServiceProxies.BehaviorDiaryDashboardServiceProxy,
        ApiServiceProxies.ABCDashboardServiceProxy,
        ApiServiceProxies.BSISDashboardServiceProxy,
        ApiServiceProxies.NurseReportToTeamDashboardServiceProxy,
        ApiServiceProxies.NutritionDiaryDashboardServiceProxy,
        ApiServiceProxies.IncidentDashboardServiceProxy,				
        ApiServiceProxies.ISTDasboardServiceProxy,
        ApiServiceProxies.ClientDashboardServiceProxy,
        ApiServiceProxies.NursingDiaryDashboardServiceProxy,
        ApiServiceProxies.NursingAssessmentDashboardServiceProxy,
        ApiServiceProxies.NursingCareDashboardServiceProxy,
        ApiServiceProxies.PinnedDashboardServiceProxy,
        ApiServiceProxies.HelpdeskDashboardServiceProxy,
        ApiServiceProxies.BehaviorPlansServiceProxy,
        ApiServiceProxies.VehicleDashboardServiceProxy,
        ApiServiceProxies.BehaviorConsultationServiceProxy,
        ApiServiceProxies.CovidDashboardServiceProxy,
        ApiServiceProxies.CovidScreeningsServiceProxy,


        ApiServiceProxies.MedAdminScreeningToolRecordsServiceProxy,
        ApiServiceProxies.MedAdminScreeningToolsServiceProxy,
        ApiServiceProxies.MedAdminScreeningToolsServiceProxy,
        ApiServiceProxies.MedAdminScreeningMastersServiceProxy,
        ApiServiceProxies.VehicalManagementsServiceProxy,
        ApiServiceProxies.VehicleMastersServiceProxy,
        ApiServiceProxies.CasemgntAssociatedStaffsServiceProxy,
        ApiServiceProxies.BehDiaryNotesServiceProxy,
        ApiServiceProxies.BehavioralDiariesServiceProxy,
        ApiServiceProxies.VocationalgroupsServiceProxy,
        ApiServiceProxies.FacilityReportsServiceProxy,

        ApiServiceProxies.FireDrillsServiceProxy,
        ApiServiceProxies.ReportDownloadInfosServiceProxy,
        ApiServiceProxies.ClientDiaryNotesServiceProxy,
        ApiServiceProxies.NurAssLabworkDocumentUploadsServiceProxy,
        ApiServiceProxies.ClientDiariesServiceProxy,
        ApiServiceProxies.IncRepCommentsServiceProxy,
        ApiServiceProxies.IncRepDocumentUploadsServiceProxy,
        ApiServiceProxies.IncRepNotificationsServiceProxy,
        ApiServiceProxies.IncRepWitnessesServiceProxy,
        ApiServiceProxies.IncidentReportsServiceProxy,
        ApiServiceProxies.NutDiaryNotesServiceProxy,
        ApiServiceProxies.NutritionDiariesServiceProxy,
        ApiServiceProxies.SiginingSheetsServiceProxy,
        ApiServiceProxies.StandingCommiteeDocsServiceProxy,
        ApiServiceProxies.MeetingApprovalsServiceProxy,
        ApiServiceProxies.NutAssDocumentUploadsServiceProxy,
        ApiServiceProxies.StandingCommiteeMeetingsServiceProxy,
        ApiServiceProxies.BSISsServiceProxy,
        ApiServiceProxies.NutritionAssLabDetailsesServiceProxy,
        ApiServiceProxies.NcpReviewTrackingsServiceProxy,
        ApiServiceProxies.NcpReportReviewsServiceProxy,
        ApiServiceProxies.HRSTReviewTrackingsServiceProxy,
        ApiServiceProxies.HRSTReportReviewsServiceProxy,
        ApiServiceProxies.NurDiaryReviewTrackingsServiceProxy,
        ApiServiceProxies.NurDiaryReportReviewsServiceProxy,
        ApiServiceProxies.NurRepTeamReviewTrackingsServiceProxy,
        ApiServiceProxies.NurRepTeamtReportReviewsServiceProxy,
        ApiServiceProxies.NurAssReviewTrackingsServiceProxy,
        ApiServiceProxies.NurAssReviewsServiceProxy,
        ApiServiceProxies.CPdocsServiceProxy,
        ApiServiceProxies.NurDiaryNotesServiceProxy,
        ApiServiceProxies.NursingDiariesServiceProxy,
        ApiServiceProxies.NutAssProblemListsServiceProxy,
        ApiServiceProxies.NutAssLabsServiceProxy,
        ApiServiceProxies.NutAssAnthropometricInfosServiceProxy,
        ApiServiceProxies.NutAssNutritionActivitiesServiceProxy,
        ApiServiceProxies.NutAssAppointmentsServiceProxy,
        ApiServiceProxies.NCPRecordsServiceProxy,
        ApiServiceProxies.IstAttendedStaffsServiceProxy,
        ApiServiceProxies.NutAssWtHistoriesServiceProxy,
        ApiServiceProxies.NursingNutritionsServiceProxy,
        ApiServiceProxies.IstAcknowledgementsServiceProxy,
        ApiServiceProxies.IndividualSpecificTrainingsServiceProxy,
        ApiServiceProxies.NurseReportToTeamsServiceProxy,
        ApiServiceProxies.HRSTNursingNeedsServiceProxy,
        ApiServiceProxies.CRSTRecommendationsServiceProxy,
        ApiServiceProxies.PertinentHistoriesServiceProxy,
        ApiServiceProxies.CRSTSwallowingProbsServiceProxy,
        ApiServiceProxies.CRSTProbwithChewingsServiceProxy,
        ApiServiceProxies.CRSTHighRiskDiagnosisesServiceProxy,
        ApiServiceProxies.ChokingRiskToolsServiceProxy,
        ApiServiceProxies.NurAssAcknowledgementsServiceProxy,
        ApiServiceProxies.NurAssReviewSystemAreasServiceProxy,
        ApiServiceProxies.NurAssImmunizationDatesServiceProxy,
        ApiServiceProxies.NurAssFollowupsServiceProxy,
        ApiServiceProxies.NurAssLabworksServiceProxy,
        ApiServiceProxies.NurAssDiagnosisesServiceProxy,
        ApiServiceProxies.NurAssAllergiesServiceProxy,
        ApiServiceProxies.NurAssVitalSignsServiceProxy,
        ApiServiceProxies.NurAsStaffSupervisionsServiceProxy,
        ApiServiceProxies.NurAssStaffRecommendationsServiceProxy,
        ApiServiceProxies.NCAcknowledgmentsServiceProxy,
        ApiServiceProxies.NursingAssessmentsServiceProxy,
        ApiServiceProxies.NurAssAddAssessmentsServiceProxy,
        ApiServiceProxies.TicketDocumentUploadsServiceProxy,
        ApiServiceProxies.RequestTicketsServiceProxy,
        ApiServiceProxies.DocumentUploadsServiceProxy,
        ApiServiceProxies.RiskConditionsServiceProxy,
        ApiServiceProxies.DocumenttypesServiceProxy,
        ApiServiceProxies.ProgramEnrollmentsServiceProxy,
        ApiServiceProxies.ProgressNotesServiceProxy,
        ApiServiceProxies.TrainedStaffsServiceProxy,
        ApiServiceProxies.RiskAreasServiceProxy,
        ApiServiceProxies.StateMastersServiceProxy,
        ApiServiceProxies.NursingCarePlansServiceProxy,
        ApiServiceProxies.ProgramsServiceProxy,
        ApiServiceProxies.SitesServiceProxy,
        ApiServiceProxies.DoctypecodesServiceProxy,
        ApiServiceProxies.EthnicitiesServiceProxy,
        ApiServiceProxies.GendersServiceProxy,
        ApiServiceProxies.HairColorsServiceProxy,
        ApiServiceProxies.InsuranceProvidersServiceProxy,
        ApiServiceProxies.LaungagesServiceProxy,
        ApiServiceProxies.MaritalStatusesServiceProxy,
        ApiServiceProxies.RelationshipsServiceProxy,
        ApiServiceProxies.ReligionsServiceProxy,
        ApiServiceProxies.UserLaungagesServiceProxy,
        ApiServiceProxies.AddresstypeServiceProxy,
        ApiServiceProxies.AddressUseServiceProxy,
        ApiServiceProxies.EyeColorsServiceProxy,
        ApiServiceProxies.BehavioralConsultationsServiceProxy,
        ApiServiceProxies.CommonServiceProxy,
        ApiServiceProxies.ABCsServiceProxy,
        ApiServiceProxies.UserAddressServiceProxy,
        ApiServiceProxies.InsuranceDetailsServiceProxy,
        ApiServiceProxies.PatientContactsServiceProxy,
        ApiServiceProxies.PatientsServiceProxy,
        ApiServiceProxies.DemosServiceProxy,
        ApiServiceProxies.AuditLogServiceProxy,
        ApiServiceProxies.CachingServiceProxy,
        ApiServiceProxies.ChatServiceProxy,
        ApiServiceProxies.CommonLookupServiceProxy,
        ApiServiceProxies.EditionServiceProxy,
        ApiServiceProxies.FriendshipServiceProxy,
        ApiServiceProxies.HostSettingsServiceProxy,
        ApiServiceProxies.InstallServiceProxy,
        ApiServiceProxies.LanguageServiceProxy,
        ApiServiceProxies.NotificationServiceProxy,
        ApiServiceProxies.OrganizationUnitServiceProxy,
        ApiServiceProxies.PermissionServiceProxy,
        ApiServiceProxies.ProfileServiceProxy,
        ApiServiceProxies.RoleServiceProxy,
        ApiServiceProxies.SessionServiceProxy,
        ApiServiceProxies.TenantServiceProxy,
        ApiServiceProxies.TenantDashboardServiceProxy,
        ApiServiceProxies.TenantSettingsServiceProxy,
        ApiServiceProxies.TimingServiceProxy,
        ApiServiceProxies.UserServiceProxy,
        ApiServiceProxies.UserLinkServiceProxy,
        ApiServiceProxies.UserLoginServiceProxy,
        ApiServiceProxies.WebLogServiceProxy,
        ApiServiceProxies.AccountServiceProxy,
        ApiServiceProxies.TokenAuthServiceProxy,
        ApiServiceProxies.TenantRegistrationServiceProxy,
        ApiServiceProxies.HostDashboardServiceProxy,
        ApiServiceProxies.PaymentServiceProxy,
        ApiServiceProxies.DemoUiComponentsServiceProxy,
        ApiServiceProxies.InvoiceServiceProxy,
        ApiServiceProxies.SubscriptionServiceProxy,
        ApiServiceProxies.InstallServiceProxy,
        ApiServiceProxies.UiCustomizationSettingsServiceProxy,
        ApiServiceProxies.PayPalPaymentServiceProxy,
        ApiServiceProxies.StripePaymentServiceProxy,
        { provide: HTTP_INTERCEPTORS, useClass: AbpHttpInterceptor, multi: true }
    ]
})
export class ServiceProxyModule { }
