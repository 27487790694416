export class AppConsts {

    static readonly tenancyNamePlaceHolderInUrl = '{TENANCY_NAME}';

    static remoteServiceBaseUrl: string;
    static remoteServiceBaseUrlFormat: string;
    static appBaseUrl: string;
    static appBaseHref: string; // returns angular's base-href parameter value if used during the publish
    static appBaseUrlFormat: string;
    static recaptchaSiteKey: string;
    static subscriptionExpireNootifyDayCount: number;

    static localeMappings: any = [];

    static readonly userManagement = {
        defaultAdminUserName: 'admin'
    };

    static readonly localization = {
        defaultLocalizationSourceName: 'Bit2EHR'
    };

    static readonly authorization = {
        encrptedAuthTokenName: 'enc_auth_token'
    };

    static readonly grid = {
        defaultPageSize: 10
    };

    static readonly FormId = {
        Client: 1,
        NursingAssessment: 2,
        NCP: 3,
        NursingDiary: 4,
        IncidentReport : 5,
        CRST : 6,
        ABC : 7,
        BC : 8,
        BSIS : 9,
        IST : 10,
        HRST : 11,
        NurseReporttoteam : 12,
        BehaviorDiary : 13,
        NutritionAssessment : 14,
        NutrtionDiary : 15,
        ClientDiary: 16,
        MedAdmin: 17,
        Firedrill: 18,
        FacilityReport:19,
        Covid: 20,
        helpdesk: 21,
        VehicleManagement: 22
       
    };
}
