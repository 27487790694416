import { NgModule } from '@angular/core';
import { NavigationEnd, RouteConfigLoadEnd, RouteConfigLoadStart, Router, RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { AppRouteGuard } from './shared/common/auth/auth-route-guard';
import { NotificationsComponent } from './shared/layout/notifications/notifications.component';


@NgModule({
    imports: [
        RouterModule.forChild([
            {
                path: 'app',
                component: AppComponent,
                canActivate: [AppRouteGuard],
                canActivateChild: [AppRouteGuard],
                children: [
                    {
                       
                        path: '',
                        children: [
                            { path: 'notifications', component: NotificationsComponent },
                            { path: '', redirectTo: '/pagenotfound', pathMatch: 'full' }
                        ]
                    },
                    {
                        path: 'main',
                        loadChildren: () => import('app/main/main.module').then(m => m.MainModule), //Lazy load main module
                        data: { preload: true }
                    },
                    {
                        path: 'admin',
                        loadChildren: () => import('app/admin/admin.module').then(m => m.AdminModule), //Lazy load admin module
                        data: { preload: true },
                        canLoad: [AppRouteGuard]
                    },
                    {
                        path: 'meeting',
                        loadChildren: () => import('app/meeting/meeting.module').then(m => m.MeetingModule), //Lazy load meeting module
                        data: { preload: true }
                    },
                    {
                        path: 'nursing',
                        loadChildren: () => import('app/nursing/nursing.module').then(m => m.NursingModule), //Lazy load meeting module
                        data: { preload: true }
                    },
                    {
                        path: 'assessment',
                        loadChildren: () => import('app/assessment/assessment.module').then(m => m.AssessmentModule), //Lazy load assessment module
                        data: { preload: true }
                    },
                    {
                        path: 'crst',
                        loadChildren: () => import('app/crst/crst.module').then(m => m.CrstModule), //Lazy load crst module
                        data: { preload: true }
                    },
                    {
                        path: 'nutrition',
                        loadChildren: () => import('app/nutrition/nutrition.module').then(m => m.NutritionModule), //Lazy load nutrition module
                        data: { preload: true }
                    },
                    {
                        path: 'behavioral',
                        loadChildren: () => import('app/behavioral/behavioral.module').then(m => m.BehavioralModule), //Lazy load behavioral module
                        data: { preload: true }
                    },
                    {
                        path: 'client',
                        loadChildren: () => import('app/client/client.module').then(m => m.ClientModule), //Lazy client nutrition module
                        data: { preload: true }
                    },
                    {
                        path: 'hrst_ist',
                        loadChildren: () => import('app/hrst_ist/Hrst_ist.module').then(m => m.Hrst_istModule), //Lazy client nutrition module
                        data: { preload: true }
                    },
                    {
                        path: 'helpdesk',
                        loadChildren: () => import('app/helpdesk/Helpdesk.module').then(m => m.HelpdeskModule), //Lazy client nutrition module
                        data: { preload: true }
                    },
                    {
                        path: 'masters',
                        loadChildren: () => import('app/masters/master.module').then(m => m.MasterModule), //Lazy client nutrition module
                        data: { preload: true }
                    },
                    {
                        path: 'incident',
                        loadChildren: () => import('app/incident/incident.module').then(m => m.IncidentModule), //Lazy client nutrition module
                        data: { preload: true }
                    },
                    {
                        path: 'fireDrills',
                        loadChildren: () => import('app/fireDrills/fireDrill.module').then(m => m.FiredrillModule), //Lazy client nutrition module
                        data: { preload: true }
                    },
                    {
                        path: 'facilityReports',
                        loadChildren: () => import('app/facilityReports/facility.module').then(m => m.FacilityModule), //Lazy client nutrition module
                        data: { preload: true }
                    },
                    {
                        path: 'vehicalManagements',
                        loadChildren: () => import('app/vehicalManagements/vehicle.module').then(m => m.VehicleModule), //Lazy client nutrition module
                        data: { preload: true }
                    },
                    {
                        path: 'medadmin',
                        loadChildren: () => import('app/medadmin/medadmin.module').then(m => m.medadminModule), //Lazy load main module
                        data: { preload: true }
                    },
                    {
                        path: 'behaviorPlan',
                        loadChildren: () => import('app/behaviorPlan/behaviorPlan.module').then(m => m.BehaviorPlanModule), //Lazy load main module
                        data: { preload: true }
                    },
                    {
                        path: 'pcp',
                        loadChildren: () => import('app/pcp/pcp.module').then(m => m.PCPModule), //Lazy load main module
                        data: { preload: true }
                    },
                    {
                        path: 'hr',
                        loadChildren: () => import('app/hr/hr.module').then(m => m.HrModule), //Lazy load main module
                        data: { preload: true }
                    },
                    {
                        path: '**', redirectTo: 'notifications'
                    }
                ]
            }
        ])
    ],
    exports: [RouterModule]
})

export class AppRoutingModule {
    constructor(
        private router: Router
    ) {
        router.events.subscribe((event) => {

            if (event instanceof RouteConfigLoadStart) {
                abp.ui.setBusy();
            }

            if (event instanceof RouteConfigLoadEnd) {
                abp.ui.clearBusy();
            }

            if (event instanceof NavigationEnd) {
                document.querySelector('meta[property=og\\:url').setAttribute('content', window.location.href);
            }
        });
    }
}
