import { AppComponentBase } from '@shared/common/app-component-base';
import { ViewChild, Component, Injector, EventEmitter, Output, Input, AfterViewInit, ChangeDetectorRef, ElementRef, SimpleChanges, ViewEncapsulation } from "@angular/core";
import { ModalDirective } from "ngx-bootstrap";
import { NotifyService } from '@abp/notify/notify.service';
import { ActivatedRoute, Router } from '@angular/router';
import {
    CommonServiceProxy, ClientInfoComponentDto, CreateOrEditProgramEnrollmentDto,
    PatientsServiceProxy, ServiceNotesServiceProxy, CreateOrEditServiceNotesDto, OpenAIResponseServiceProxy,
    ServiceEnrollmentsServiceProxy, ServiceEnrollmentDto, UserAdditionalInfoUserLookupTableDto,
    SipServiceProxy, OldSipForNotesDto, ChatRequest, UserAdditionalInfoServiceProxy
} from '@shared/service-proxies/service-proxies';
import { Validators, FormControl } from '@angular/forms';
import * as moment from 'moment';
import { finalize, timeout } from "rxjs/operators";
import { CommonFooterComponent, FooterobjectDto } from '../../CommonFooter/common-footer-componet';
import { Shifts } from '@shared/common/shifts';
import { OpenaiService } from '../../../openai.service';
import { error } from '@angular/compiler/src/util';


@Component({
    selector: 'create-edit-service-note-v2',
    templateUrl: './create-edit-service-notes-v2.component.html',
    styleUrls: ['./create-edit-service-notes-v2.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class CreateEditServiceNotesComponentV2 extends AppComponentBase {

    @Input() data = new CreateOrEditServiceNotesDto();
    @Output() onNewNoteSave = new EventEmitter<any>();
    @Output() onNoteCancel = new EventEmitter<any>();

    @ViewChild('commonfootermodal', { static: true }) commonfootermodalchild: CommonFooterComponent;
    _footerobj: FooterobjectDto = new FooterobjectDto();

    today: Date;
    clientInfo: ClientInfoComponentDto = new ClientInfoComponentDto();//data will come from parent

    @ViewChild('noteForm', { static: true }) form;
    @ViewChild('shift', { static: true }) shift;
    @ViewChild('soundwave', { static: false }) soundwave: ElementRef;

    displayDescriptionInDialog: boolean = false;
    displayPCPGoalsInDialog: boolean = false;
    isFormLoading = false;
    isClientLoading = false;
    clientData: any[] = [];
    clientDataFiltered: any[] = [];
    blocked: boolean = false;
    pheight: string;
    weight: number;
    serviceNoteId: number = 0;
    location: string = "";
    serviceNote: CreateOrEditServiceNotesDto = new CreateOrEditServiceNotesDto();
    programEnrollList: CreateOrEditProgramEnrollmentDto[] = new Array();
    allProgramsList: CreateOrEditProgramEnrollmentDto[] = new Array();

    servicesListBackup: ServiceEnrollmentDto[] = new Array();
    dayServicesListBackup: ServiceEnrollmentDto[] = new Array();
    enrolledServicesList: ServiceEnrollmentDto[] = new Array();
    selectedEnrolledService: ServiceEnrollmentDto = null;


    showStartOrEndTime = true;
    private sub: any;
    startTime: any; //serviceNote.noteStartTime
    endTime: any; //serviceNote.noteEndTime
    maxStartDate = new Date();
    minStartDate = new Date();
    maxEndDate = new Date();
    totalHours: number = 0;
    mode: string = "new";
    _view: boolean = false;
    selectedProgram: CreateOrEditProgramEnrollmentDto = null;
    siteOccupancy: string = "";
    programLoading: boolean = false;
    servicesLoading: boolean = false;
    isError: boolean = false;
    errorDescription: string = "";
    programEnrollmentId: number = 0;

    shifts: Shifts = new Shifts();
    shiftList: any;
    shiftListFiltered: any;
    selectedShift: any = null;
    shiftStartTime: string = "";
    noEnrolledServices: boolean = false;

    readonly _RETAINER_SERVICE_ID: string = "CLGHR";
    readonly _DAY_PROGRAM_ID: string = "Day";
    disableWeekendsToggle: boolean = false;
    displayServiceDescriptionDialog:boolean = false;

    dateValue: Date;
    daysDisabled: number[] = [];
    serviceDate: any;
    sipDataList: OldSipForNotesDto = new OldSipForNotesDto();
    isLoadingSipData: boolean = false;

    prompt: string = '';
    
    selectedGoals: string = ''
    selectedPlans: string = ''
    selectedShiftActivities: string = '';
    isActivityAssistanceProvided: boolean = false;
    isIssueReported: boolean = false;

    text = '';
    typingText = '';
    index = 0;
    speed = 6; // milliseconds

    additionalComment:string = '';
    isAIResponseLoading: boolean;
    isAIRegeneratingLoading: boolean;
    selectedDay: string = '';

    isSaving: boolean = false;

    shiftActivities: string[] = [];
    logicallySortedActivities: string[] = [];
    sortActivityBy = 'alphabatical';

    allUsers: UserAdditionalInfoUserLookupTableDto[];
    selectedEmployee: UserAdditionalInfoUserLookupTableDto = new UserAdditionalInfoUserLookupTableDto();

    constructor(
        injector: Injector,
        private _commonServiceProxy: CommonServiceProxy,
        private cd: ChangeDetectorRef,
        private route: ActivatedRoute,
        private _notifyService: NotifyService,
        private _patientsServiceProxy: PatientsServiceProxy,
        private _router: Router,
        private _serviceNotesServiceProxy: ServiceNotesServiceProxy,
        private _serviceEnrollmentsServiceProxy: ServiceEnrollmentsServiceProxy,
        private _sipServiceProxy: SipServiceProxy,
        private _openAIResponseServiceProxy: OpenAIResponseServiceProxy,
        private _userAdditionalInfosServiceProxy: UserAdditionalInfoServiceProxy,
    ) {
        super(injector);
        (moment as any).fn.toString = function () { return this.format("L"); };
        moment.locale("en");
        this.today = new Date();

        this.sub = this.route.params.subscribe(params => {
            if (params) {
                this.serviceNoteId = params.serviceNoteId;
                this.mode = params.mode;
            }
        });
        this.mode == 'view' ? this._view = true : this._view = false;
    }

    ngOnChanges(changes: SimpleChanges) {
        this.sipDataList = new OldSipForNotesDto();
        this.getAllClients();
        this.getAllPrograms();
        this.getAllEnrolledServices();
        this.getAllDayServices();//not enrolled yet

        if(changes.data.currentValue){
            this.mode = 'dialog';
            this.serviceNote = changes.data.currentValue;
            //this.serviceNote.id = 0;
            if (this.serviceNote.id == 0) {
                this.programEnrollmentId = this.serviceNote.programEnrollmentId;
                this.initializeNewNewNoteInfo(this.serviceNote.id);
            }
            else {
                this.mode = "edit";
                this.serviceNoteId = this.serviceNote.id;
                this.getServiceNoteById();
            }

            //this.selectShiftBySerciceCodeAndNoteDate(this.serviceNote.serviceCode, this.serviceNote.noteDate);
        }
    }

    /*selectShiftBySerciceCodeAndNoteDate(serviceCode: string, noteDate: moment.Moment) {
        if (serviceCode.indexOf("CLGH") > -1) {
            this.selectedShift = this.shifts.shiftList[2];
            if (noteDate.day() == 0 || noteDate.day() == 6) {//Satu and Sunday
                this.selectedShift = this.shifts.shiftList[4];
            }
           this.onShiftChangeV2(this.selectedShift);
        }
    }*/

    ngOnInit(): void {
        debugger;
        this.shiftList = this.shifts.shiftList;
        this.shiftListFiltered = this.shiftList;
        //this.getAllServicesForDropDownList();
        this.getAllClients();
        this.getAllPrograms();
        this.getAllEnrolledServices();
        this.getAllDayServices();//not enrolled yet
        this.maxEndDate.setHours(this.maxEndDate.getHours() + 16);
        this.minStartDate.setDate(this.minStartDate.getDate() - 400);
        if (this.mode == "new") {
            this.initializeNewNewNoteInfo(0);
        } else {
            if (this.serviceNoteId) {
                this.getServiceNoteById();
            }
        }
    }

    getAllClients() {
        this.isClientLoading = true;
        this._commonServiceProxy.getAllClients()
            .pipe(finalize(() => { this.isClientLoading = false; }))
            .subscribe(data => {
                this.isClientLoading = false;
                this.clientData = data;
                this.clientDataFiltered = data;
            });
    }
    searchClient(event: any) {
        var trimmed = event.query.trim();
        if (trimmed.length == 0) {
            this.form.reset();
        }
        //filter client data except for trimmed
        this.clientDataFiltered = this.clientData.filter(c => c.clientName.toLowerCase().includes(trimmed.toLowerCase()));
    }

    onClientSelect(event) {
        this.selectedProgram = null;
        //this.onProgramChange(this.selectedProgram);

        this.selectedEnrolledService = null;
        //this.onServiceChange(this.selectedEnrolledService);

        this.selectedShift = null;
        this.programEnrollmentId = 0;
        //this.onShiftChange(this.selectedShift);


        this.resetErrorInfo();
        this.setClientInfo(event);
    }

    onClientClear(event): void {
        this.setClientInfo(event);
    }

    setClientInfo(data): void {
        this.clientInfo.clientID = data.clientID;
        this.serviceNote.patientsId = data.clientID;
        this.clientInfo.clientName = data.clientName;
        this.clientDataFiltered = [];
        this.serviceNote.serviceDescription = "";
        this.serviceNote.programDeptMappingId = 0;
        this.sipDataList = new OldSipForNotesDto();
        if (data.clientID != undefined) {
           // this.getProgramsByClientId();
        }
        else {
            this.programEnrollList = [];
            this.enrolledServicesList = [];
            this.selectedProgram = null;
            this.totalHours = 0;
            this.serviceNote.programDeptMappingId = 0;
            this.serviceNote.siteName = "";
            this.serviceNote.siteOccupancy = 0;
            this.serviceNote.siteId = 0;
            this.serviceNote.serviceId = 0;
            this.serviceNote.shiftId = 0;
        }
    }

    initializeNewNewNoteInfo(noteId: number) {
        this.serviceNote.serviceDescription = "";
        this.serviceNote.clientStatus = "Present";
        this.serviceNote.staffUserId = this.appSession.userId;
        this.selectedEmployee = new UserAdditionalInfoUserLookupTableDto();
        this.selectedEmployee.id = this.appSession.userId;
        this.selectedEmployee.displayName = this.appSession.user.name + " " + this.appSession.user.surname;

        if (this.serviceNote.serviceId > 0) {

            this.shiftList = this.shifts.shiftList;
            this.shiftListFiltered = this.shiftList;
            
            this.maxEndDate.setHours(this.maxEndDate.getHours() + 16);
            this.minStartDate.setDate(this.minStartDate.getDate() - 400);

            this.clientInfo.clientName = this.serviceNote.patientName;
            this.clientInfo.clientID = this.serviceNote.patientsId;            
            if(this.serviceNote.serviceStartDateTime){
                if (this.serviceNote.serviceStartDateTime.year() != 0) {
                    this.startTime = moment(this.serviceNote.serviceStartDateTime, 'YYYY-MM-DD HH:mm');
                    this.serviceDate = this.startTime;
                    let date = this.serviceDate;
                    let day = moment(date).format('dd');
                    this.selectedDay = moment(date).format('dddd');
                    this.shiftListFiltered = this.shiftList.filter(s => s.weekDays.includes(day));
                }
            }
            if (this.serviceNote.serviceEndDateTime){
                if (this.serviceNote.serviceEndDateTime.year() != 0) {
                    this.endTime = moment(this.serviceNote.serviceEndDateTime, 'YYYY-MM-DD HH:mm');
                }
            }

            if (this.serviceNote.shiftId) {
                this.selectedShift = this.shiftList.filter(s => s.id == this.serviceNote.shiftId)[0];
            } else {
                //find weeday by this.servieDate
                let day = moment(this.serviceDate).format('ddd');
                this.selectedShift = this.shiftList.filter(s => s.services.indexOf(this.serviceNote.serviceCode) > -1 && s.weekDays.includes(day))[0];
            }

            this.totalHours = moment(this.endTime).diff(moment(this.startTime), 'minutes') / 60;

            //this.selectedShift = this.shiftList.filter(s => s.id == this.serviceNote.shiftId)[0];
            
            if (this.allProgramsList.length == 0) {
                //wait for 3 seconds and then call function
                setTimeout(() => {
                    this.onShiftChangeV2(this.selectedShift);
                }, 3000);
            } else {
                this.onShiftChangeV2(this.selectedShift);
            }

            this.updateFooter();
            this.isFormLoading = false;
            setTimeout(() => {
                this.growContentbox('typingText1');
            }, 2000);           
            this.isFormLoading = false;
        } else {
            this.clientInfo.clientName = "";
            this.serviceNote.programDeptMappingId = 0;
            this.serviceNote.serviceId = 0;
            this.serviceNote.id = noteId;
            this.serviceNote.shiftId = 0;
            this.serviceNote.siteId = 0;
            this.serviceNote.siteName = "";
            this.serviceNote.siteOccupancy = 0;
            this.serviceNote.patientsId = 0;
        }
    }

    updateFooter() {
        if (this.serviceNote.serviceId != null) {
            this._footerobj.userid = this.serviceNote.creatorUserId;
            if (!this._footerobj.userid)
                this._footerobj.userid = this.appSession.userId;
            try { this._footerobj.createdon = this.serviceNote.createdOn.toDate(); } catch { }
            try { this._footerobj.modifiedon = this.serviceNote.modifiedOn.toDate(); } catch { }
            try { this._footerobj.modifiedid = this.serviceNote.lastModifierUserId; } catch { }
            this.cd.detectChanges();
            try { this.commonfootermodalchild.inputfooterData = this._footerobj; } catch { }
        }
    }

    getServiceNoteById(): void {
        this.isFormLoading = true;
        this._serviceNotesServiceProxy.getServiceNoteByIdForViewOrEdit(this.serviceNoteId).
            pipe(finalize(() => { })).
            subscribe(result => {
                if (result != null) {
                    if (result.id == undefined) {
                        this.serviceNote.serviceId = undefined;
                        this.isFormLoading = false;
                        return;
                    }
                    this.serviceNote = result;
                    this.clientInfo.clientName = this.serviceNote.patientName;
                    this.clientInfo.clientID = this.serviceNote.patientsId;

                    if (this.serviceNote.serviceStartDateTime.year() != 0) {
                        this.startTime = moment(this.serviceNote.serviceStartDateTime, 'YYYY-MM-DD HH:mm');

                        this.serviceDate = this.startTime;
                        let date = this.serviceDate;
                        this.selectedDay = moment(date).format('dddd');
                    }

                    if (this.serviceNote.serviceEndDateTime.year() != 0) {
                        this.endTime = moment(this.serviceNote.serviceEndDateTime, 'YYYY-MM-DD HH:mm');
                    }
                    this.totalHours = this.endTime.diff(this.startTime, 'minutes') / 60;

                    this.selectedShift = this.shiftList.filter(s => s.id == this.serviceNote.shiftId)[0];
                    this.onShiftChangeV2(this.selectedShift);
                    
                    this.updateFooter();
                    this.isFormLoading = false;
                    setTimeout(() => {
                        this.growContentbox('typingText1');
                    }, 500);

                    this.selectedEmployee = new UserAdditionalInfoUserLookupTableDto();
                    this.selectedEmployee.id = result.staffUserId;
                    this.selectedEmployee.displayName = result.staffUserName;
                }
            }, error => {
                console.log(error);
                this.isError = true;
                this.isFormLoading = false;
            });
    }

    getAllPrograms() {
        this._patientsServiceProxy.getAllPrograms().subscribe(result => {
            this.allProgramsList = result;
        });
    }


    getAllEnrolledServices() {
        /*this.serviceNote.patientsId, this.programEnrollmentId, this.serviceNote.serviceStartDateTime */
        this.servicesLoading = true;
        this.noEnrolledServices = false;
        this._serviceEnrollmentsServiceProxy.getAllServicesForDropDownListV2().
                pipe(finalize(() => { })).
                subscribe(result => {
                    if (result != null) {
                        result = result.filter(r => r.serviceIdentifier != this._RETAINER_SERVICE_ID);
                        this.servicesListBackup = result;
                        this.servicesLoading = false
                    } 
                });
    }

    getAllDayServices() {
        /*this.serviceNote.patientsId, this.programEnrollmentId, this.serviceNote.serviceStartDateTime */
        this.servicesLoading = true;
        this.noEnrolledServices = false;
        this._serviceEnrollmentsServiceProxy.getAllDayServicesForDropDownList().
                pipe(finalize(() => { })).
                subscribe(result => {
                    if (result != null) {
                        result = result.filter(r => r.serviceIdentifier != this._RETAINER_SERVICE_ID);
                        this.dayServicesListBackup = result;
                        this.servicesLoading = false
                    } 
                });
    }

    getEnrolledServicesByProgramId(services: ServiceEnrollmentDto[], clientId: number , progEnrollmentId:number) {
        this.servicesLoading = true;
        this.noEnrolledServices = false;
        /*
            a.PatientId == clientId && a.ProgramEnrollmentId == programEnrollmentId
            && a.IsDeleted == false
            && a.ServicePlanStartDate.Value <= serviceDate && a.ServicePlanEndDate.Value >= serviceDate
        */
        this.enrolledServicesList = services.filter(r => r.patientId == clientId
            && r.programEnrollmentId == progEnrollmentId && r.programDeptMappingId == this.serviceNote.programDeptMappingId);
            /*&& r.servicePlanStartDate <= this.serviceDate
            && r.servicePlanEndDate >= this.serviceDate*/
        this.servicesLoading = false;
        //return;
    }

    goToClientProfile(editMode): void {
        if (this.serviceNote.patientsId) {
            this._router.navigate(['/app/client/patients/create-or-edit-patients-modal/', this.serviceNote.patientsId, editMode]);
        }
    }

    enrolledServices() {
        this.enrolledServicesList = []
        for (let i = 0; i < this.enrolledServicesList.length; i++) {
            if (this.serviceNote.programDeptMappingId == this.enrolledServicesList[i].programDeptMappingId) {
                let dto = new ServiceEnrollmentDto();
                dto.serviceName = this.enrolledServicesList[i].serviceName;
                dto.serviceIdentifier = this.enrolledServicesList[i].serviceIdentifier;
                dto.id = this.enrolledServicesList[i].serviceId;
                dto.programDeptMappingId = this.enrolledServicesList[i].programDeptMappingId;
                this.enrolledServicesList.push(dto);
            }
        }

        if (this.enrolledServicesList.length == 1) {
            this.selectedEnrolledService = this.enrolledServicesList[0];
            this.serviceNote.serviceId = this.enrolledServicesList[0].id;
        }
    }

    notEnrolledServices() {
        this.enrolledServicesList = [];
        for (let i = 0; i < this.servicesListBackup.length; i++) {
            if (this.serviceNote.programDeptMappingId == this.servicesListBackup[i].programDeptMappingId) {
                let dto = new ServiceEnrollmentDto();
                dto.serviceName = this.servicesListBackup[i].serviceName;
                dto.serviceIdentifier = this.servicesListBackup[i].serviceIdentifier;
                dto.id = this.servicesListBackup[i].id;
                dto.programDeptMappingId = this.servicesListBackup[i].programDeptMappingId;
                this.enrolledServicesList.push(dto);
            }
        }

        if (this.enrolledServicesList.length == 1) {
            this.selectedEnrolledService = this.enrolledServicesList[0];
            this.serviceNote.serviceId = this.enrolledServicesList[0].id;
        }
    }
       

    save() {
        this.resetErrorInfo();
        if (this.serviceNote.patientsId == 0) {
            this.isError = true;
            this.errorDescription = "Please select client";
            return false;
        }

        if (this.serviceDate == undefined || this.serviceDate == null) {
            this.isError = true;
            this.errorDescription = "Please select Service Date";
            return false;
        }

        if (this.serviceNote.shiftId == 0) {
            this.isError = true;
            this.errorDescription = "Please select Shift";
            return false;
        }

        if (this.serviceNote.programDeptMappingId == 0) {
            this.isError = true;
            this.errorDescription = "Please select program";
            return false;
        }
        if (this.serviceNote.serviceId == 0) {
            this.isError = true;
            let deptName = "day or res";
            if (this.selectedProgram) {
                deptName = this.selectedProgram.deptName;
            }
            this.errorDescription = "Please select " + this.selectedProgram.deptName + " Service";
            return false;
        }

        if (this.endTime == undefined) {
            this.isError = true;
            this.errorDescription = "Please select Service Date";
            return false;
        }

        if (this.serviceNote.clientStatus == undefined || this.serviceNote.clientStatus == "") {
            this.isError = true;
            this.errorDescription = "Please select Client Status";
            return false;
        }

        if (this.serviceNote.clientStatus == 'Present' && this.totalHours <= 0) {
            this.isError = true;
            this.errorDescription = "Total Hours must be more than zero, please enter positive value which is less than " + this.selectedShift.shiftHours + ", please enter valid value or change date and time";
            return false;
        }

        if (this.totalHours > this.selectedShift.shiftHours) {
            this.isError = true;
            this.errorDescription = "Total Hours must be  " + this.selectedShift.shiftHours + "  hours or less, please enter valid value or change date and time";
            return false;
        }


        if (this.serviceNote.serviceDescription.trim() == "") {
            this.isError = true;
            this.errorDescription = "Please enter Service Description";
            return false;
        }

        this.serviceNote.serviceStartDateTime = this.startTime;
        this.serviceNote.serviceEndDateTime = this.endTime;
        this.serviceNote.noteDate = this.serviceDate;
        this.isSaving = true;
        this._serviceNotesServiceProxy.createOrEdit(this.serviceNote).
            pipe(finalize(() => { })).
            subscribe(result => {
                //send data to parent   
                this.serviceNote.id = result;
                this.serviceNote.createdBy = this.appSession.user.name;
                this.serviceNote.createdOn = moment();
                this.onNewNoteSave.emit(this.serviceNote);
                this.isSaving = false;
            }, erro => {
                console.debug(erro);
                this.isError = true;
                this.errorDescription = "An error occurred while saving the service note, please try again";
                this.isSaving = false;
            });
    }
    close() {
        this.onNoteCancel.emit();
    }

    editView() {
        if (this.serviceNote.serviceId != undefined) {
            this._router.navigate(['app/client/services-note', this.serviceNote.serviceId, "edit"]);
        }
    }

    onServiceChange(service: ServiceEnrollmentDto) {
        this.resetErrorInfo();
        if (service == null) {
            this.serviceNote.serviceId = 0;
        } else {
            this.serviceNote.serviceId = service.serviceId;
            this.serviceNote.programDeptMappingId = service.programDeptMappingId;
            this.serviceNote.serviceCode = service.serviceIdentifier;
            this.serviceNote.programEnrollmentId = service.programEnrollmentId;
        }
    }


    onProgramChangeV2(prog: CreateOrEditProgramEnrollmentDto): void {
        this.resetErrorInfo();
        if (prog != null) {
            this.programEnrollmentId = prog.id;
            this.serviceNote.siteName = prog.treatmentSiteName;
            this.serviceNote.programDeptMappingId = prog.program;
            this.serviceNote.siteOccupancy = prog.siteOccupancy;
            this.serviceNote.siteId = prog.siteId;
            //this.getEnrolledServicesByProgramId();
        } else {
           this.enrolledServicesList = [];
        }
    }


    onShiftChangeV2(shift) {
        //select program
        this.resetErrorInfo();
        this.serviceNote.shiftId = 0;
        this.serviceNote.programDeptMappingId = 0;
        this.programEnrollmentId = 0;
        this.serviceNote.serviceId = 0;
        this.selectedEnrolledService = null;
        this.selectedProgram = null;
        this.totalHours = 0;
        this.programEnrollList = [];
        this.enrolledServicesList = [];
        this.selectedShift = null;
        this.shiftActivities = [];
        if (shift == null) {
            return;
        }
        this.selectedShift = shift;
        this.shiftActivities = shift.activities;
        let pName = shift.program;
        let services: ServiceEnrollmentDto[] = new Array();
        let clientId = this.serviceNote.patientsId;
        let progEnrollmentId = this.programEnrollmentId;
        if (pName == "Day") {
            pName = "Voc"
            clientId = 0;
            services = this.dayServicesListBackup;
        } else {
            services = this.servicesListBackup;
        }
        this.programEnrollList = this.allProgramsList.filter(p => p.programName.includes(pName) && p.patientsFKID == this.serviceNote.patientsId);
        this.selectedProgram = this.programEnrollList[0];
        this.programEnrollmentId = this.selectedProgram.id;
        this.serviceNote.programDeptMappingId = this.selectedProgram.program;
        this.serviceNote.siteId = this.selectedProgram.siteId;
        pName == "Voc" ? progEnrollmentId = 0 : progEnrollmentId = this.programEnrollmentId;
        this.getEnrolledServicesByProgramId(services, clientId, progEnrollmentId);
        this.serviceNote.shiftId = shift.id;
        this.shiftStartTime = shift.startTime;
        this.serviceNote.siteId = this.selectedProgram.siteId

        //fix following code for iOS safari browser
        //this.startTime = moment(moment(this.startTime).format('YYYY-MM-DD') + " " + shift.startTime).format('YYYY-MM-DDTHH:mm:ss');
        this.startTime = moment(this.startTime).format('YYYY-MM-DD') + " " + shift.startTime;
        this.startTime = moment(this.startTime, 'YYYY-MM-DD HH:mm');

        //this.endTime = moment(moment(this.startTime).add(shift.shiftHours * 60, 'minutes').toDate()).format('YYYY-MM-DDTHH:mm:ss');
        this.endTime = moment(this.startTime).add(shift.shiftHours * 60, 'minutes');
        this.totalHours = this.endTime.diff(this.startTime, 'minutes') / 60;
        
        //select aurhorized services
        let authServices = this.enrolledServicesList.filter(s => s.programDeptMappingId == this.selectedProgram.program && s.programEnrollmentId == this.programEnrollmentId);
        //no authorized services for Day program
        if (pName == "Voc") {
            authServices = this.enrolledServicesList;
        }

        let shiftServices = authServices.filter(s => shift.services.includes(s.serviceIdentifier));
        if (this.mode == 'dialog' || this.mode == 'edit') {
            this.selectedEnrolledService = shiftServices.filter(s => s.serviceIdentifier == this.serviceNote.serviceCode)[0];//order of first service
            if (this.selectedEnrolledService) {
                this.serviceNote.serviceId = this.selectedEnrolledService.serviceId;
                return;
            }
        }
        if (progEnrollmentId > 0) {
            this.enrolledServicesList = shiftServices;
            this.selectedEnrolledService = shiftServices.filter(s => s.serviceIdentifier == shift.services[0])[0];//order of first service
            if (this.selectedEnrolledService == undefined) {
                this.selectedEnrolledService = shiftServices.filter(s => s.serviceIdentifier == shift.services[1])[0];//order of first service
            }
            if (this.selectedEnrolledService == undefined) {
                this.selectedEnrolledService = shiftServices.filter(s => s.serviceIdentifier == shift.services[2])[0];//order of first service
            }
            if (this.selectedEnrolledService) {
                this.serviceNote.serviceId = this.selectedEnrolledService.serviceId;
            }
        }

        //this.enableDisableDays(shift);
    }

    enableDisableDays(shift) {
        if (shift == null) {
            this.daysDisabled = [];
            return;
        }
        if (shift.weekDays == "M-F") {
            this.daysDisabled = [0, 6];
        } else {
            this.daysDisabled = [1, 2, 3, 4, 5];
        }
    }

    timeSelected(event): void {
        this.resetErrorInfo();
        this.totalHours = 0;
        if (event.element.id == "ejstartDateTime") {
            //this.startTime = moment(moment(event.value).format('YYYY-MM-DD') + " " + this.selectedShift.startTime).format('YYYY-MM-DDTHH:mm:ss');
            this.startTime = moment(event.value).format('YYYY-MM-DD') + " " + this.selectedShift.startTime;
            // Use moment to parse the time
            this.startTime = moment(this.startTime, 'YYYY-MM-DD HH:mm');

            this.serviceNote.noteDate = this.startTime;
            this.serviceNote.serviceStartDateTime = this.startTime;
            if (this.selectedShift.shiftHours > 0) {
                //this.endTime = moment(moment(this.startTime).add(this.selectedShift.shiftHours * 60, 'minutes').toDate()).format('YYYY-MM-DDTHH:mm:ss');
                this.endTime = moment(this.startTime).add(this.selectedShift.shiftHours * 60, 'minutes');
            }
        }

        if (event.element.id == "ejstartDateTime" && this.endTime) {
            //this.totalHours = moment(this.endTime).diff(moment(this.startTime), 'minutes') / 60;
            this.totalHours = this.endTime.diff(this.startTime, 'minutes') / 60;
        }
        else {
            if (this.startTime)
            //this.totalHours = moment(event.value).diff(moment(this.startTime), 'minutes') / 60;
              this.totalHours = moment(event.value).diff(this.startTime, 'minutes') / 60;
        }
        if (this.totalHours > this.selectedShift.shiftHours) {
            this.isError = true;
            this.errorDescription = "Total Hours must be " + this.selectedShift.shiftHours + " hours or less, please enter valid value or change date and time";
        }
    }

    totalHoursChange(event) {
        this.resetErrorInfo();
        if (this.totalHours > this.selectedShift.shiftHours) {
            this.isError = true;
            this.errorDescription = "Total Hours must be  " + this.selectedShift.shiftHours + "  hours or less, please enter valid value or change date and time";
        } else {
            this.endTime = moment(this.startTime).add(parseFloat(event.target.value) * 60, 'minutes');
            //this.endTime = moment(this.startTime).add(shift.shiftHours * 60, 'minutes');
        }
    }

    dateCleared(elementId) {
        this.totalHours = 0;
        if (elementId == "ejstartDateTime") {
            this.startTime = undefined;
            this.serviceNote.serviceStartDateTime = this.startTime;
        } else {
            this.endTime = undefined;
            this.serviceNote.serviceEndDateTime = this.endTime;
        }
    }

    serviceDescriptionChange(): void {
        this.resetErrorInfo();
    }
    resetErrorInfo(): void {
        this.isError = false;
        this.errorDescription = "";
    }

    deleteServiceNote(): void {
        this.message.confirm(
            this.l('Are you sure you want to delete Service Note for Client: ' + this.serviceNote.patientName + ' ?'), ' ',
            (isConfirmed) => {
                if (isConfirmed) {
                    this._serviceNotesServiceProxy.delete(this.serviceNoteId)
                        .subscribe(() => {
                            this.close();
                            this.notify.success('Service Note Successfully Deleted', "", { position: ['middle', 'right'] });
                        });
                }
            }

        );
    }
    createServiceNote() {
        this.mode = "edit";
        this._view = false;
        this._router.navigate(['app/client/services-notes', 0, "new"]);
    }

    showPCPGoalsInDialog(): void {
        if (this.serviceNote.pcpGoals) {
            //this.displayPCPGoalsInDialog = true;
        }
    }

    selectServiceDate(event: Date | null) {
        if (event == null) {
            this.shiftListFiltered = this.shiftList;
            this.selectedShift = null;
            return; 
        }
        let date = new Date(event);
        let day = moment(date).format('dd');
        this.selectedDay = moment(date).format('dddd');
        this.shiftListFiltered = this.shiftList.filter(s => s.weekDays.includes(day));
        this.updateDateTime(this.serviceDate, this.selectedShift);
    }

    updateDateTime(date: any, shift: any) {
        if (shift) {
            this.startTime = moment(date).format('YYYY-MM-DD') + " " + shift.startTime;
            this.startTime = moment(this.startTime, 'YYYY-MM-DD HH:mm');
            this.endTime = moment(this.startTime).add(shift.shiftHours * 60, 'minutes');
            this.totalHours = this.endTime.diff(this.startTime, 'minutes') / 60;
        }
    }

    openDateBox($event) {
        document.getElementById("ServiceDate").click();
    }

    UpdateComments(comments): void {
        this.serviceNote.serviceDescription = comments
        if (this.serviceNote.clientStatus != 'Present') {
            this.totalHours = 0;
            this.startTime = this.endTime;
        } else {
            let shift = this.selectedShift;
            this.startTime = moment(this.startTime).format('YYYY-MM-DD') + " " + shift.startTime;
            this.startTime = moment(this.startTime, 'YYYY-MM-DD HH:mm');

            this.endTime = moment(this.startTime).add(shift.shiftHours * 60, 'minutes');
            this.totalHours = this.endTime.diff(this.startTime, 'minutes') / 60;
        }
    }

    closeServiceDescriptionDialog() {
        this.displayServiceDescriptionDialog = false;
    }

    showAIDescriptionDialog(openFromBtn:boolean = false) {
        if (!this.isGranted('Pages.ServiceNotes.AI') || this.serviceNote.clientStatus != 'Present'){
            return;
        }
        if ((this.mode == 'new' || this.mode == 'dialog') || (this.mode == 'edit' && this.isGranted('Pages.ServiceNotes.Edit'))) {
            if (this.serviceNote) {
                if (this.serviceNote.serviceDescription.trim() == "" || openFromBtn) {
                    this.isLoadingSipData = true;
                    this.displayServiceDescriptionDialog = true;
                    this.getSIPData();
                    this.logicallySortedActivities = [...this.shiftActivities];
                }
            }
        }
    }

    getSIPData() {
        //this.sipDataList = new OldSipForNotesDto();
        this._sipServiceProxy.getOldSIPById(this.serviceNote.patientsId, this.serviceNote.serviceId, this.serviceDate).
            pipe(finalize(() => { })).
            subscribe(result => {
                this.sipDataList = result;
                this.isLoadingSipData = false;
            },
                error => {
                    this.isLoadingSipData = false;
                    this.displayServiceDescriptionDialog = false
                    console.error('Error fetching data from SIP API: ', error);
            });
    }

    growDialogBox() {
        let parent = document.getElementById('SIPDialog');
        if (parent) {
            let child = parent.querySelector("[role='dialog']");
            child.setAttribute("style", "height:fit-content;width: 80%;z-index: 1004;top: 0px;transform: none;opacity: 1;");
        }
    }

    closeAIDescriptionDialog(status: string) {
        this.growContentbox('typingText1');
        if (status == 'Ok') {
            this.callAzureAI();
        }
        this.displayServiceDescriptionDialog = false;
    }

    onSIPCheckboxChange(event: any, type:string) {
        if (event.target.type == "checkbox") {
            const isChecked = event.target.checked;
            const value = event.target.value;
            if (type == "goal") {
                if (isChecked) {
                    this.selectedGoals = this.selectedGoals + value + '\n';
                }
                else {
                    //remove value from selectedGoals
                    this.selectedGoals = this.selectedGoals.replace(value + '\n', '');
                }
            }
            else if (type == 'ip') {
                if (isChecked) {
                    this.selectedPlans = this.selectedPlans + value + '\n';
                }
                else {
                    //remove value from selectedPlans
                    this.selectedPlans = this.selectedPlans.replace(value + '\n', '');
                }
            }
            else if (type == 'activity') {
                if (isChecked) {
                    if (this.selectedShiftActivities.indexOf(value) == -1) {
                        this.selectedShiftActivities = this.selectedShiftActivities + value + ', ';
                    }
                }
                else {
                    //remove value from selectedShiftActivities
                    this.selectedShiftActivities = this.selectedShiftActivities.replace(value + ', ', '');
                }
            }
            else if (type == 'assistance') {
                this.isActivityAssistanceProvided = isChecked;
            }
            else if (type == 'noissue') {
                this.isIssueReported = isChecked;
            }
        }
        if (this.selectedGoals.length == 0 && this.selectedPlans.length == 0 && this.selectedShiftActivities.length == 0) {
            this.serviceNote.serviceDescription = '';
            return;
        }
    }
    sortActivities(sortBy: string) {
        if (sortBy == 'alphabatical') {
            this.logicallySortedActivities = [...this.shiftActivities];
            this.shiftActivities = [...this.logicallySortedActivities].sort();
            this.sortActivityBy = "logical";
        } else {
            this.shiftActivities = [...this.logicallySortedActivities];
            this.sortActivityBy = "alphabatical";
        }
    }
    callAzureAI() {
        this.serviceNote.serviceDescription = '';
        this.growContentbox('typingText1');
        this.isAIResponseLoading = true;
        this.isAIRegeneratingLoading = true;
        let promptResponse: ChatRequest = new ChatRequest();
        promptResponse.clientName = this.clientInfo.clientName;
        promptResponse.outcomes = this.getOutcomesInList();
        promptResponse.goals = this.selectedGoals;
        promptResponse.plans = this.selectedPlans;
        promptResponse.additionalComment = this.additionalComment;
        promptResponse.activities = this.selectedShiftActivities;
        promptResponse.isAssistanceProvided = this.isActivityAssistanceProvided;
        promptResponse.isIssueReported = this.isIssueReported;
        this._openAIResponseServiceProxy.fetchAIResponse(promptResponse).subscribe(
            (data) => {
                this.isAIResponseLoading = false;
                this.typeEffect(data, 'typingText1');
            },
            (error) => {
                console.error('Error fetching data from Azure OpenAI API:', error);
                this.isAIResponseLoading = false;
            }
        );
    }

    getOutcomesInList() {
        let str = "";
        this.sipDataList.outcomes.forEach(element => {
            str = str + element + '\n';
        });
        return str;
    }

    typeEffect(response: string, textarea:any): void {
        this.text = response;
        this.serviceNote.serviceDescription = '';
        this.index = 0;
       
        const interval = setInterval(() => {
            if (this.index < this.text.length) {
                this.serviceNote.serviceDescription += this.text.charAt(this.index);
                this.index++;
                this.growContentbox(textarea);

            } else {
                clearInterval(interval);
            }
            if (this.index == this.text.length) {
                this.isAIRegeneratingLoading = false;
            }
        }, this.speed);
    }

    growContentbox(textareaId: string) {
        const textarea = document.getElementById(textareaId);
        if (textarea) {
            textarea.style.height = "auto";
            textarea.style.height = (textarea.scrollHeight) + "px";
        }
    }

    filterUsers(event?: any) {
        let wordSearch = event.query.trim();
        this._userAdditionalInfosServiceProxy.getAllUserForTableDropdown(wordSearch).subscribe(result => {
            this.allUsers = result;
        });
    }

    onSelectUser(event) {
        this.selectedEmployee = event;
        this.serviceNote.staffUserId = event.id;
        this.serviceNote.staffUserName = event.displayName;
    }
    onClearUser(event) {
        this.serviceNote.staffUserId = this.appSession.userId;
        this.serviceNote.staffUserName = this.appSession.user.name + " " + this.appSession.user.surname;
    }
}

