import { CanDeactivate } from '@angular/router';


export class DeactivateGuard implements CanDeactivate<any> {

    canDeactivate(component: any) {
       
    return component.canDeactivate();
    }
}
