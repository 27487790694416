import { AbpModule } from '@abp/abp.module';
import * as ngCommon from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppLocalizationService } from '@app/shared/common/localization/app-localization.service';
import { AppNavigationService } from '@app/shared/layout/nav/app-navigation.service';
import { CommonModule } from '@shared/common/common.module';
import { UtilsModule } from '@shared/utils/utils.module';
import { BsDatepickerModule, ModalModule } from 'ngx-bootstrap';
import { PaginatorModule } from 'primeng/paginator';
import { TableModule } from 'primeng/table';
import { AppAuthService } from './auth/app-auth.service';
import { AppRouteGuard } from './auth/auth-route-guard';
import { CommonLookupModalComponent } from './lookup/common-lookup-modal.component';
import { EntityTypeHistoryModalComponent } from './entityHistory/entity-type-history-modal.component';
import { EntityChangeDetailModalComponent } from './entityHistory/entity-change-detail-modal.component';
import { DateRangePickerInitialValueSetterDirective } from './timing/date-range-picker-initial-value.directive';
import { DatePickerInitialValueSetterDirective } from './timing/date-picker-initial-value.directive';
import { DateTimeService } from './timing/date-time.service';
import { TimeZoneComboComponent } from './timing/timezone-combo.component';
import { DialogModule } from 'primeng/dialog';
import { MaterialModule } from '../material-module';
import { CommonFooterComponent } from './CommonFooter/common-footer-componet';
import { CreateEditServiceNotesComponent } from '../common/serviceNotes/create-edit-service-notes.component';
import { CreateEditServiceNotesComponentV2 } from '../common/serviceNotes/V2/create-edit-service-notes-v2.component';

import { AutoCompleteModule } from 'primeng/autocomplete';
import { DateTimePickerModule } from "@syncfusion/ej2-angular-calendars";

import { TooltipModule as NgToolTip } from 'primeng/tooltip';

@NgModule({
    imports: [
        ngCommon.CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ModalModule.forRoot(),
        UtilsModule,
        AbpModule,
        CommonModule,
        TableModule,
        PaginatorModule,
        MaterialModule,
        DialogModule,
        BsDatepickerModule.forRoot(),
        NgToolTip,
        DateTimePickerModule,
        AutoCompleteModule,
    ],
    declarations: [
        CreateEditServiceNotesComponent,
        CreateEditServiceNotesComponentV2,
        CommonFooterComponent,
        TimeZoneComboComponent,
        CommonLookupModalComponent,
        EntityTypeHistoryModalComponent,
        EntityChangeDetailModalComponent,
        DateRangePickerInitialValueSetterDirective,
        DatePickerInitialValueSetterDirective
    ],
    exports: [
        CreateEditServiceNotesComponent,
        CreateEditServiceNotesComponentV2,
        TimeZoneComboComponent,
        CommonLookupModalComponent,
        EntityTypeHistoryModalComponent,
        EntityChangeDetailModalComponent,
        DateRangePickerInitialValueSetterDirective,
        DatePickerInitialValueSetterDirective,
        MaterialModule,
        CommonFooterComponent
    ],
    providers: [
        DateTimeService,
        AppLocalizationService,
        AppNavigationService
    ]
})
export class AppCommonModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: AppCommonModule,
            providers: [
                AppAuthService,
                AppRouteGuard
            ]
        };
    }
}
