import { AppComponentBase } from "../../../../shared/common/app-component-base";
import { ViewChild, Component, Injector, ChangeDetectorRef } from "@angular/core";
import { ModalDirective } from "ngx-bootstrap";
import { ActivatedRoute } from "@angular/router";
import { CommonServiceProxy, CommonFooterDto, UserListDto } from "../../../../shared/service-proxies/service-proxies";

@Component({
    selector: 'commonfootermodal',
    templateUrl: './common-footer-componet.html'
    //providers: [FooterobjectDto]
})

export class CommonFooterComponent extends AppComponentBase {
    @ViewChild('commonfootermodal', { static: true}) modal: ModalDirective;
    data: any;
    _footerobj: FooterobjectDto = new FooterobjectDto();
    set inputfooterData(inputdata: FooterobjectDto) {
        debugger;
        this._footerobj = inputdata;
       
        this.show(this._footerobj.userid, this._footerobj.createdon, this._footerobj.modifiedid, this._footerobj.modifiedon);
    }
    get inputfooterData(): FooterobjectDto {
        debugger;
        return this._footerobj;
    }


    userListDto: UserListDto[];
    userName: string;
    modifiedBy: string;
    createddate = new Date();
    ModifiedDate: Date;
    today: Date;

    userid: number;
    constructor(
        injector: Injector,
        private _commonServiceProxy: CommonServiceProxy,
        private route: ActivatedRoute,
        private cd: ChangeDetectorRef,
       // private _footerobj: FooterobjectDto
    ) {
        super(injector);
        this.userName = this.appSession.user.name + " " + this.appSession.user.surname;
        this.userid = this.appSession.userId;
        this.today = new Date();
        //this._commonServiceProxy.getAllUsers().subscribe(result => {
        //    
        //    this.userListDto = result;
        //})
    }

    ngOnInit() {
        
        var _dtd = this.data;
        this._footerobj = this.data;;
        
    }

    show(createdUserid?: number, createdDt?: any, modifiedUserid?: number, modifyDt?: any): void {
        
        debugger;
            this._commonServiceProxy.getAllUsers().subscribe(result => {
                       
                this.userListDto = result;
                var cuser = this.userListDto.filter(s => s.id == createdUserid);
                var muser = this.userListDto.filter(s => s.id == modifiedUserid);
                this.userName = cuser[0].fullName;// + ' ' + cuser[0].surname;
                if (createdDt)
                    this.createddate = createdDt;
                else {
                    this.createddate = this.today;
                }
                if (modifiedUserid > 0) {
                    this.modifiedBy = muser[0].fullName;// + ' ' + muser[0].surname;
                    this.ModifiedDate = modifyDt;
                }
                this.cd.detectChanges()
            })
        
      

    }

}

export class FooterobjectDto {
    userid?: number;
    modifiedid?: number;
    createdon: Date;
    modifiedon: Date;
}
