export class Shifts {
    shiftList = [{
        id: 1,
        fullName: "T-S 12:00am to 8:00am (WDAON)",
        shortName: "WDAON",
        program: "Res",
        programDeptMappingIds: [2, 4],
        timeRange: "12:00am to 8:00am",
        startTime: "12:00 am",
        endTime: "8:00 am",
        shiftHours: 8,
        weekHours: 40,
        weekDays: "Tue,Wed,Thu,Fri,Sat",
        isActive: true,
        services: ["CLGH"],
        activities: ["Wake-up", "Safety check", "Bathroom Routine", "Shower", "Hygiene", "Dressing", "Medications", "Breakfast", "Logging", "Monitoring", "Snack", "ER Visit for health issues"]
    },
    {
        id: 2,
        fullName: "M-F 8:00am to 4:00pm (DAY)",
        shortName: "DAY",
        program: "Day",
        programDeptMappingIds: [1, 3],
        timeRange: "8:00am to 4:00pm",
        startTime: "8:00 am",
        endTime: "4:00 pm",
        shiftHours: 8,
        weekHours: 40,
        weekDays: "Mon,Tue,Wed,Thu,Fri",
        isActive: true,
        services: ["Day", "CDS11", "CDS21", "CDSG", "DH11", "DHSG", "FPMS", "DH21", "ESJD"],
        activities: ["Dressing", "Breakfast", "Exercise", "Skill-building", "Training", "Job or Employment", "Therapy", "Outdoor time", "Lunch", "Quiet time", "Snack", "ER Visit for health issues", "Home Visit"]
    },
    {
        id: 3,
        fullName: "M-F 4:00pm to 12:00am (WDE)",
        shortName: "WDE",
        program: "Res",
        programDeptMappingIds: [2, 4],
        timeRange: "4:00pm to 12:00am",
        startTime: "4:00 pm",
        endTime: "12:00 am",
        shiftHours: 8,
        weekHours: 40,
        weekDays: "Mon,Tue,Wed,Thu,Fri",
        isActive: true,
        services: ["CLGH21", "CLGH11", "CLGH"],
        activities: ["Dinner", "Medications", "Social activities", "Games", "Play", "Watching TV", "Relaxation", "Calming", "Safety check", "Bedtime", "Sleeping", "ER Visit for health issues", "Home Visit"]
    },
    {
        id: 4,
        fullName: "SU-M 12:00am to 8:00am (WEAON)",
        shortName: "WEAON",
        program: "Res",
        programDeptMappingIds: [2, 4],
        timeRange: "12:00am to 8:00am",
        startTime: "12:00 am",
        endTime: "8:00 am",
        shiftHours: 8,
        weekHours: 16,
        weekDays: "Sun,Mon",
        isActive: true,
        services: ["CLGH"],
        activities: ["Bathing", "Bedtime", "Breakfast", "Dinner", "Hygiene", "Leisure", "Medications", "Outdoor Time", "Quiet Time", "Recreation", "Relaxation", "Safety Check", "Shower", "Wake-up", "Watching TV", "ER Visit for health issues", "Home Visit"]
    },
    {
        id: 5,
        fullName: "S-SU 8:00am to 12:00am (WED)",
        shortName: "WED",
        program: "Res",
        programDeptMappingIds: [2, 4],
        timeRange: "8:00am to 12:00am",
        startTime: "8:00 am",
        endTime: "12:00 am",
        shiftHours: 16,
        weekHours: 32,
        weekDays: "Sat,Sun",
        isActive: true,
        services: ["CLGH21", "CLGH11", "CLGH"],
        activities: ["Bathing", "Bedtime", "Breakfast", "Dinner", "Hygiene", "Leisure", "Lunch", "Medications", "Outdoor Time", "Quiet Time", "Recreation", "Relaxation", "Safety Check", "Shower", "Wake-up", "Watching TV", "ER Visit for health issues", "Home Visit"]
    },
    {
        id: 6,
        fullName: "M-F 8:00am to 4:00pm (ILOD)",
        shortName: "ILOD",
        program: "Res",
        programDeptMappingIds: [2, 4],
        timeRange: "8:00am to 4:00pm",
        startTime: "8:00 am",
        endTime: "4:00 pm",
        shiftHours: 8,
        weekHours: 40,
        weekDays: "Mon,Tue,Wed,Thu,Fri",
        isActive: true,
        services: ["CLGH21", "CLGH11"],
        activities: ["Bathing", "Breakfast", "Dressing", "Eating", "Exercise", "Grooming", "Handwashing", "Hygiene", "Lunch", "Outdoor time", "Quiet time", "Skill-building", "Snack", "Therapy", "Bathroom Routine", "ER Visit for health issues", "Home Visit"]
    }
    ]
}
